
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.DoYouKnowWrapper {
    width: 100%;
    // max-width: 400px;
    max-width: 624px;
    @include xl {
        max-width: 624px;
    }
    @include xxl {
        @apply text-4xl;
        max-width: 824px;
    }
    @media screen and (max-width: 1023px) {
        padding-bottom: 300px;
    }
}
.points {
    .point:nth-child(2n - 1) {
        margin-right: 33px;
    }
}
.point {
    // flex: 100%;
    flex: 47%;
    position: relative;
    padding-left: 24px;
    min-height: 96px;
    @apply mb-10;
    @include xl {
        // flex: 47%;
        @apply mb-20;
    }

    &:before {
        display: block;
        content: "";
        width: 8px;
        height: 96px;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #0c4d93;
    }
}
.pointText {
    @apply text-base;
    @include xxl {
        @apply text-xl;
    }
}
.num {
    color: #b4d1f1;
    line-height: 1;
    @apply text-3xl;
    @include xxl {
        @apply text-4xl;
    }
}
.title {
    @apply text-3xl;
    @include xxl {
        @apply text-4xl;
    }
}
